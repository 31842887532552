.questions {
	height: 100%;
}

.questions .questions__empty {
	margin: var(--gutter-2) 0 0 0;
}

.questions .questions__mask {
	height: 100%;
	overflow-y: auto;
}

.questions .questions__list {
	margin: var(--gutter-4) 0 0 0;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: var(--gutter-2);
	padding: 0;
}

.questions .questions__list .list__item {
	list-style: none;
	display: flex;
	width: 100%;
}

.questions .questions__list .list__item .item__icon {
	margin: 0 var(--gutter-1) 0 0;
	background-color: var(--c-gey-7);
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
}

.questions .questions__list .list__item .item__content {
	flex: 1;
}

.questions .questions__list .list__item .item__meta {
	display: flex;
	grid-gap: var(--gutter-2);
	margin: var(--gutter-1) 0 0 0;
}

.questions .questions__list .list__item .item__meta .meta__item {
	text-transform: capitalize;
	display: flex;
	grid-gap: var(--gutter-1);
}

.questions .questions__list .list__item .item__meta .meta__item svg {
	width: var(--gutter-2);
	height: auto;
	max-height: var(--gutter-2);
	color: var(--c-grey-5);
}

.questions .questions__list .list__item .item__meta .meta__item.meta__item--group {
	display: flex;
	align-items: center;
	grid-gap: var(--gutter-2);
}

.questions .questions__list .list__item--error .item__icon {
	background-color: var(--c-error-bg);
}

.questions .questions__list .list__item--success .item__icon {
	background-color: var(--c-success-bg);
}

@media screen and (min-width: 768px) {
	.questions .questions__list {
		grid-gap: var(--gutter-4);
	}
}

@media screen and (min-width: 1024px) {
}
