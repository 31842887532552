.wrap {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	padding: 0 var(--gutter-2);
	width: 100%;
	max-width: var(--mq-max);
	margin: 0 auto;
}

@media screen and (min-width: 768px) {
	.wrap {
		padding: 0 var(--gutter-4);
		grid-gap: var(--gutter-2);
	}
}

@media screen and (min-width: 1024px) {
	.wrap {
		padding: 0 var(--gutter-10);
	}
}
