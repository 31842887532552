.overlay {
	transition: all 200ms;
	background-color: rgba(0, 0, 0, 0.8);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	pointer-events: none;
}

.overlay.overlay--active {
	opacity: 1;
	pointer-events: all;
}
