.tag {
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 60px;
	padding: var(--gutter-1) var(--gutter-2);
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-family: var(--f-oswald);
	font-weight: 700;
	font-size: 1.2rem;
	line-height: 1.2rem;
	color: var(--c-white);
}
