.nav {
	background-color: var(--c-white);
	font-weight: 500;
	color: var(--c-black);
	height: var(--nav-height);
}

.nav .nav__wrap {
	height: 100%;
}

.nav .nav__container {
	grid-column: 1 / -1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.nav .nav__logo .logo__text {
	font-size: 3rem;
	line-height: 3rem;
}

.nav .nav__logo .logo__img {
	width: auto;
	height: 26px;
}

.nav .nav__logo .logo__text a {
	text-decoration: none;
	color: var(--c-black);
}

.nav .nav__list {
	display: flex;
	margin: 0;
	padding: 0;
}

.nav .nav__list .list__item {
	margin: 0 var(--gutter-1) 0 0;
	list-style: none;
	display: flex;
	align-items: center;
}

.nav .nav__list .list__item:last-child {
	margin: 0;
}

.nav .nav__list .list__item.list__item--desktop {
	display: none;
}

.nav .nav__list .list__item.list__item--reset a {
	text-decoration: none;
	display: flex;
	align-items: center;
}

.nav .nav__list .list__item.list__item--reset .item__icon {
	background-color: var(--c-grey-8);
	color: var(--c-grey-2);
}

.nav .nav__list .list__item.list__item--reset a {
	color: var(--c-grey-4);
}

.nav .nav__list .list__item.list__item--questions {
	background-color: var(--c-grey-9);
	border-radius: 4px;
	padding: var(--gutter-1);
	font-weight: 700;
	cursor: pointer;
}

.nav .nav__list .list__item .item__icon {
	background-color: var(--c-blue);
	border-radius: 13px;
	margin: 0 var(--gutter-1) 0 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 26px;
	height: 26px;
	color: var(--c-white);
}

.nav .nav__list .list__item .item__icon svg {
	width: 60%;
	height: auto;
	max-height: 60%;
}

.scrnlg {
	display: none;
}

@media screen and (min-width: 768px) {
	.scrnlg {
		display: contents;
	}

	.nav .nav__list .list__item {
		margin: 0 var(--gutter-3) 0 0;
	}
}

@media screen and (min-width: 1024px) {
	.nav .nav__list .list__item.list__item--desktop {
		display: flex;
	}

	.nav .nav__list .list__item.list__item--reset {
		margin: 0 0 0 var(--gutter-4);
	}
}
