.home {
	background-color: var(--c-blue-3);
	position: relative;
	min-height: 100vh;
	display: flex;
	align-items: center;
	color: var(--c-white);
}

.home .home__bg,
.home .home__video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	pointer-events: none;
}

.home .home__bg {
	opacity: 0.2;
}

.home .home__video {
	opacity: 0.1;
}

.home .home__wrap {
	position: relative;
	height: 100%;
}

.home .home__box {
	grid-column: 1 / -1;
}

.home .home__box .box__card {
	background-color: var(--c-white);
	border-radius: 12px;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
	padding: var(--gutter-4);
	color: var(--c-black);
}

.home .home__title {
	font-size: 6rem;
	line-height: 6rem;
	text-align: center;
	margin: 0 0 var(--gutter-2) 0;
}

.home .home__logo {
	max-width: 200px;
	filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.3));
}

.home .home__form .form__list {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: var(--gutter-2);
	margin: 0;
	padding: 0;
}

.home .home__form .form__list .list__item {
	list-style: none;
}

.home .home__footer {
	margin: var(--gutter-4) 0 0 0;
	font-size: 1.2rem;
	line-height: 2.2rem;
	text-align: center;
}

@media screen and (min-width: 768px) {
	.home .home__box {
		grid-column: 3 / -3;
	}

	.home .home__box .box__card {
		padding: var(--gutter-7);
		text-align: center;
	}
}

@media screen and (min-width: 1024px) {
	.home .home__box {
		grid-column: 5 / -5;
	}
}
