.footer {
	background-color: var(--c-white);
	padding: var(--gutter-2) 0;
}

.footer .footer__wrap {
	height: 100%;
	align-items: center;
	grid-gap: var(--gutter-2);
}

.footer .footer__title {
	grid-column: 1 / -1;
	margin: 0;
}

.footer .footer__disclaimer {
	grid-column: 1 / -1;
	display: inline;
	align-items: center;
	font-size: 1rem;
	line-height: 2rem;
	opacity: 0.6;
	height: 100%;
}

.footer .footer__disclaimer a {
	color: var(--c-blue-3);
}

.footer__credit {
	display: flex;
	align-items: center;
	grid-column: 1 / -1;
}

.footer__credit svg {
	margin: 0 0 0 var(--gutter-1);
	width: auto;
	height: 16px;
}

@media screen and (min-width: 768px) {
	.footer {
		padding: var(--gutter-4) 0;
	}

	.footer .footer__title {
		grid-column: 1 / 3;
	}

	.footer .footer__disclaimer {
		grid-column: 4 / 10;
	}

	.footer__credit {
		grid-column: 11 / -1;
		justify-content: flex-end;
	}
}
