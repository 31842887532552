html {
	font-size: calc((10 / 16) * 100%);
}

body {
	font-weight: 500;
	font-size: 1.4rem;
	line-height: 2.4rem;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-family: var(--f-oswald);
	text-transform: uppercase;
	margin: 0;
}

h1,
.h1 {
	font-size: 4rem;
	line-height: 5rem;
}

h2,
.h2 {
	font-size: 2.2rem;
	line-height: 3.2rem;
}

h3,
.h3 {
	font-size: 1.8rem;
	line-height: 2.8rem;
}

h4,
.h4 {
	font-size: 1.6rem;
	line-height: 2.6rem;
}

h5,
.h5 {
	font-size: 1.4rem;
	line-height: 2.4rem;
}

h6,
.h6 {
	font-size: 1.4rem;
	line-height: 2.4rem;
}

p {
	margin: 0 0 var(--gutter-2) 0;
}

p:last-child {
	margin: 0;
}

.typography.typography--body {
	font-family: var(--f-sans-serif);
}

/* font families */

.typography--gyparody {
	font-family: var(--f-gyparody);
}

.typography--oswald {
	font-family: var(--f-oswald);
}

/* style */

.typography.typography--shadow {
	text-shadow: 3px 3px 0px var(--c-black);
}

/* custom */

.typography.typography--question {
	text-shadow: 3px 3px 0px var(--c-black);
	font-family: var(--f-itc-korinna);
	font-size: 4rem;
	line-height: 5rem;
}

.typography.typography--reader {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

.typography.typography--value {
	text-shadow: 3px 3px 0px var(--c-black);
	font-family: var(--f-oswald);
	font-weight: 700;
	font-size: 2rem;
	line-height: 2rem;
	color: sandybrown;
}

@media screen and (min-width: 1024px) {
	.typography.typography--question {
		font-size: 5rem;
		line-height: 6rem;
	}

	.typography.typography--value {
		font-size: 4rem;
		line-height: 4rem;
	}
}
