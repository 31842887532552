.board {
	position: relative;
	min-height: calc(100vh - var(--nav-height));
}

.board .board__loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.board .board__loading svg {
	transition: all 200ms;
	animation: spin 1s linear infinite;
	width: 60px;
	height: auto;
}

.board .board__mask {
	position: relative;
	grid-column: 1/ -1;
}

.board .board__mask::after {
	pointer-events: none;
	background: linear-gradient(to left, var(--c-blue), transparent);
	position: absolute;
	top: 0;
	right: 0;
	width: 25vw;
	height: 100%;
	content: '';
}

.board .board__mask .mask__mask {
	width: 100%;
	overflow-x: auto;
}

.board .board__table {
	border: 6px var(--c-black) solid;
	border-collapse: collapse;
	position: relative;
	table-layout: fixed;
	width: 100%;
	min-height: calc(100vh - var(--nav-height) - (var(--gutter-2) * 2));
}

.board .board__table th,
.board .board__table td {
	border: none;
	border-bottom: 6px var(--c-black) solid;
	border-right: 6px var(--c-black) solid;
	text-align: center;
}

.board .board__table th {
	padding: var(--gutter-2);
	width: 160px;
}

.board .board__table .table__cell,
.board .board__table td {
	padding: 0;
	height: 70px;
}

.board .board__table .table__cell--empty {
	background-color: rgba(0, 0, 0, 0.5);
}

.board .board__table .table__value {
	transition: all 200ms;
	background-color: transparent;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: var(--gutter-2);
	text-decoration: none;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.board .board__table .table__value:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 768px) {
	.board .board__table {
		min-height: calc(100vh - var(--nav-height) - (var(--gutter-4) * 2));
		width: 100%;
	}

	.board .board__mask::after {
		display: none;
	}

	.board .board__table th {
		width: auto;
	}
}

@media screen and (min-width: 1024px) {
	.board .board__table {
		min-height: calc(100vh - var(--nav-height) - (var(--gutter-10) * 2));
	}
}
