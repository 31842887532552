label {
	display: inline-block;
	margin: 0 0 var(--gutter-2) 0;
	font-weight: 700;
}

.label.label--desktop {
	display: none;
}

.label.label--screenreader {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

@media screen and (min-width: 1024px) {
	.label.label--desktop {
		display: inline-block;
	}
}
