.clue {
	position: relative;
	height: calc(100vh - var(--nav-height));
}

.clue .clue__progress {
	position: absolute;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	top: 0;
	left: 0;
	width: 100%;
}

.clue .clue__progress .progress__progress {
	transition: all 200ms;
	display: block;
	width: 100%;
	height: 4px;
	margin: 0;
	padding: 0;
	-webkit-appearance: none;
}

.clue .clue__progress .progress__time {
	margin: var(--gutter-2);
}

progress::-webkit-progress-bar {
	background-color: rgba(0, 0, 0, 0.5);
}

progress::-webkit-progress-value {
	background-color: red;
}

.clue .clue__section {
	height: calc(100% - var(--clue-form-height));
}

.clue .clue__wrap {
	height: 100%;
}

.clue .clue__content,
.clue .clue__form {
	grid-column: 1 / -1;
}

.clue .clue__content {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-y: auto;
}

.clue .clue__text {
	margin: var(--gutter-2) 0 0 0;
}

.clue .clue__airdate {
	margin: var(--gutter-4) 0 0 0;
	font-family: var(--f-itc-korinna);
	opacity: 0.7;
}

.clue .clue__form {
	background-color: var(--c-blue-3);
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: var(--clue-form-height);
	display: flex;
	align-items: flex-end;
}

.clue .clue__form .form__list {
	display: grid;
	grid-template-columns: 3fr 1fr;
	grid-gap: var(--gutter-2);
	align-items: flex-end;
	margin: 0;
	padding: var(--gutter-2);
	width: 100%;
}

.clue .clue__form .form__list li {
	list-style: none;
}

@media screen and (min-height: 580px) {
	.clue .clue__content {
		justify-content: center;
		overflow: visible;
	}
}

@media screen and (min-width: 1024px) {
	.clue .clue__form {
		background-color: transparent;
	}
}
