.dialog {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.dialog__overlay {
	background-color: rgba(0, 0, 0, 0.8);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.dialog__wrap {
	height: 100%;
}

.dialog__content {
	grid-column: 1 / -1;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.dialog__box {
	background-color: var(--c-white);
	border-radius: 4px;
	padding: var(--gutter-2);
	min-width: 300px;
	color: var(--c-black);
}

.dialog__text {
	margin: var(--gutter-2) 0;
}

.dialog__btns {
	display: flex;
	flex-direction: column;
	grid-gap: var(--gutter-2);
	margin: 0;
	padding: 0;
}

.dialog__btns li {
	list-style: none;
}

/* alert */

.dialog__alert {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.dialog__alert .dialog__icon {
	background-color: var(--c-grey-7);
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
}

.dialog__alert .dialog__icon svg {
	width: 30px;
	height: auto;
}

.dialog__alert .dialog__title {
	margin: var(--gutter-2) 0;
}

/* alert error */

.dialog.dialog--error .dialog__alert .dialog__icon {
	background-color: var(--c-error-bg);
}

.dialog.dialog--error .dialog__alert .dialog__icon svg {
	color: var(--c-error);
}

/* alert success */

.dialog.dialog--success .dialog__alert .dialog__icon {
	background-color: var(--c-success-bg);
}

.dialog.dialog--success .dialog__alert .dialog__icon svg {
	color: var(--c-success);
}

.dialog.dialog--center .dialog__box {
	text-align: center;
}

@media screen and (min-width: 768px) {
	.dialog__box {
		border-radius: var(--gutter-4);
		padding: var(--gutter-5);
	}
}
