@font-face {
	font-family: 'Gyparody';
	font-weight: 900;
	font-style: normal;
	font-display: swap;
	src: url('fonts/gyparody/gyparody-heavy.ttf') format('truetype');
}

@font-face {
	font-family: 'Oswald';
	font-weight: normal;
	font-style: normal;
	font-display: swap;
	src: url('fonts/oswald/oswald.ttf') format('truetype');
}

@font-face {
	font-family: 'ITC Korinna';
	font-weight: normal;
	font-style: normal;
	font-display: swap;
	src: url('fonts/itc-korinna/itc-korinna.eot');
	src: url('fonts/itc-korinna/itc-korinna.eot?#iefix') format('embedded-opentype'),
		url('fonts/itc-korinna/itc-korinna.woff2') format('woff2'),
		url('fonts/itc-korinna/itc-korinna.woff') format('woff'),
		url('fonts/itc-korinna/itc-korinna.ttf') format('truetype'),
		url('fonts/itc-korinna/itc-korinna.svg#ITC Korinna Regular') format('svg');
}

* {
	box-sizing: border-box;
}

:root {
	--mq-s: 470px;
	--mq-m: 768px;
	--mq-l: 1024px;
	--mq-xl: 1200px;
	--mq-max: 1400px;

	--gutter-1: 8px;
	--gutter-2: calc(2 * var(--gutter-1));
	--gutter-3: calc(3 * var(--gutter-1));
	--gutter-4: calc(4 * var(--gutter-1));
	--gutter-5: calc(5 * var(--gutter-1));
	--gutter-6: calc(6 * var(--gutter-1));
	--gutter-7: calc(7 * var(--gutter-1));
	--gutter-8: calc(8 * var(--gutter-1));
	--gutter-9: calc(9 * var(--gutter-1));
	--gutter-10: calc(10 * var(--gutter-1));
	--gutter-11: calc(11 * var(--gutter-1));
	--gutter-12: calc(12 * var(--gutter-1));
	--gutter-13: calc(13 * var(--gutter-1));
	--gutter-14: calc(14 * var(--gutter-1));
	--gutter-15: calc(15 * var(--gutter-1));
	--gutter-16: calc(16 * var(--gutter-1));
	--gutter-17: calc(17 * var(--gutter-1));
	--gutter-18: calc(18 * var(--gutter-1));
	--gutter-19: calc(19 * var(--gutter-1));
	--gutter-20: calc(20 * var(--gutter-1));

	--c-grey-10: #fafafa;
	--c-grey-9: #f5f5f5;
	--c-grey-8: #eeeeee;
	--c-grey-7: #e0e0e0;
	--c-grey-6: #bdbdbd;
	--c-grey-5: #9e9e9e;
	--c-grey-4: #757575;
	--c-grey-3: #616161;
	--c-grey-2: #424242;
	--c-grey-1: #212121;

	--c-error: #721c24;
	--c-error-bg: #f8d7da;
	--c-success: #155724;
	--c-success-bg: #d4edda;

	--c-black: #000000;
	--c-white: #ffffff;

	--c-blue: #0430bb;
	--c-blue-2: #050bb8;
	--c-blue-3: #030886;
	--c-yellow: gold;

	--f-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	--f-gyparody: 'Gyparody';
	--f-itc-korinna: 'ITC Korinna';
	--f-oswald: 'Oswald';
	--f-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

	--nav-height: 60px;

	--clue-form-height: var(--gutter-11);
}

body {
	margin: 0;
	font-family: var(--f-sans-serif);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: var(--f-code);
}

@keyframes spin {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}
