.panel {
	transition: all 200ms;
	transform: translateX(100%);
	background-color: var(--c-white);
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	padding: var(--gutter-2);
	width: 100%;
	height: 100vh;
	color: var(--c-grey-1);
}

.panel .panel__close {
	position: absolute;
	top: var(--gutter-3);
	right: var(--gutter-3);
}

.panel.panel--active {
	transform: translateX(0);
}

@media screen and (min-width: 768px) {
	.panel {
		padding: var(--gutter-4);
	}
}

@media screen and (min-width: 1024px) {
	.panel {
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
		width: 500px;
	}
}
