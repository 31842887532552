button {
	appearance: none;
}

.btn {
	transition: all 200ms;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--c-blue);
	border-radius: 6px;
	border: none;
	padding: var(--gutter-2) var(--gutter-4);
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 700;
	color: var(--c-white);
	cursor: pointer;
	overflow: hidden;
}

.btn .btn__overlay {
	transition: all 200ms;
	background-color: rgba(0, 0, 0, 0.3);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: '';
	opacity: 0;
}

.btn:hover .btn__overlay {
	opacity: 0.2;
}

.btn .btn__text {
	position: relative;
}

.btn.btn--yellow {
	background-color: var(--c-yellow);
	color: rgba(0, 0, 0, 0.7);
}

.btn.btn--border {
	background-color: transparent;
	border: 2px var(--c-blue) solid;
	color: var(--c-blue);
}

.btn.btn--border:hover {
	background-color: var(--c-blue);
	border-color: var(--c-blue);
	color: var(--c-white);
}

.btn.btn--icon {
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	padding: var(--gutter-2);
}

.btn.btn--icon svg {
	transition: all 200ms;
	width: 18px;
	height: auto;
	color: var(--c-grey-3);
}

.btn.btn--icon:hover svg {
	color: var(--c-white);
}

.btn.btn--icon.btn--grey {
	background-color: var(--c-grey-7);
}
