.section {
	padding: var(--gutter-2) 0;
	width: 100%;
}

@media screen and (min-width: 768px) {
	.section {
		padding: var(--gutter-4) 0;
	}
}

@media screen and (min-width: 1024px) {
	.section {
		padding: var(--gutter-10) 0;
	}
}
