input {
	transition: all 200ms;
	display: block;
	background-color: var(--c-grey-9);
	border: 1px var(--c-grey-7) solid;
	border-radius: 4px;
	padding: var(--gutter-2);
	font-size: 1.6rem;
	line-height: 1.6rem;
	width: 100%;
}

input[type='button'],
input[type='submit'] {
	background-color: goldenrod;
	border: none;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-family: var(--f-oswald);
	color: rgba(0, 0, 0, 0.7);
	cursor: pointer;
	appearance: none;
}

input[type='button']:hover,
input[type='submit']:hover {
	box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
}

::placeholder {
	color: var(--c-grey-4);
}

:-ms-input-placeholder {
	color: var(--c-grey-4);
}

::-ms-input-placeholder {
	color: var(--c-grey-4);
}
